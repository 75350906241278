import React from 'react';
import { UitkCard } from 'uitk-react-cards';
import { UitkLink } from 'uitk-react-link';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkIllustrations } from 'uitk-react-illustrations';
import { UitkSpacing } from 'uitk-react-spacing';
import { useStores } from 'utils/useStore';
var GoGuidesBannerCampaign = function () {
  var goGuidesStore = useStores().goGuidesStore;
  var contentData = goGuidesStore.fetchBannerCampaign(goGuidesStore.pageUrlData);
  if (!(contentData === null || contentData === void 0 ? void 0 : contentData.displayBannerCampaign)) {
    return null;
  }
  var message = '이제 ** 호텔스닷컴에서 ** 네이버페이로 ** 결제하실 ** 수 ** 있어요';
  var url = 'https://kr.hotels.com/lp/b/naver-pay';
  var img = 'https://goguides.azureedge.net/media/ettfsxnl/naver-pay-logo.png';
  return React.createElement(React.Fragment, null, React.createElement(UitkSpacing, {
    margin: "six",
    padding: "six",
    border: ["inlinestart", "inlineend"]
  }, React.createElement(UitkCard, {
    padded: true,
    border: true
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    space: "four"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement(UitkIllustrations, {
    className: "banner-logo",
    alt: message,
    url: img
  }))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkLink, {
    inline: true
  }, React.createElement("a", {
    href: url,
    target: "_blank",
    rel: "noopener noreferrer"
  }, message)))))));
};
export default GoGuidesBannerCampaign;